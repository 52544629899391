import * as React from "react";
import { Component } from "react";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import { Portal } from "react-portal";

type Props = {
  children: Node;
};

type State = {
  messages: Array<Object>;
};

export default class MessageProvider extends React.Component<Props, State> {
  static childContextTypes = {
    addMessage: PropTypes.func,
  };

  state = {
    messages: [],
  };

  getChildContext() {
    return {
      addMessage: this.addMessage,
    };
  }

  addMessage = (node: Node) => {
    let timeout = 5000;
    if (typeof node === "object") {
      if (node.props.timeout) {
        timeout = node.props.timeout;
      }
    } else {
      node = <div>{node}</div>;
    }

    const id = Math.floor(Math.random() * 10000000);
    this.setState({
      messages: [
        ...this.state.messages,
        {
          id,
          node,
        },
      ],
    });

    setTimeout(() => {
      this.onClose(id);
    }, timeout);
  };

  onClose = (id: number) => {
    this.setState({
      messages: [...this.state.messages.filter((m) => m.id !== id)],
    });
  };

  render() {
    return (
      <div>
        <Portal>
          <Wrapper visible={this.state.messages.length > 0}>
            {this.state.messages.map((msg) => {
              if (typeof msg.node.type === "string") {
                const { timeout, ...props } = msg.node.props;

                return <msg.node.type key={msg.id} {...props} />;
              }

              return (
                <msg.node.type
                  key={msg.id}
                  onClose={() => this.onClose(msg.id)}
                  {...msg.node.props}
                />
              );
            })}
          </Wrapper>
        </Portal>
        {this.props.children}
      </div>
    );
  }
}

const Wrapper = styled.div`
  min-width: 300px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 12px;
  display: ${(p) => (p.visible ? "block" : "none")};

  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
`;
