import * as React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import * as PropTypes from "prop-types";
import Utilities from "Utilities";

const currentUserQuery = gql`
  query currentUserQuery {
    currentUser {
      id
      name
      isAdmin
      recipient {
        id
      }
      flags {
        communicationPastPresentFuture
        featureflagPaymentGateway
        confirmCloseOpportunity
        listsV2ImportV2
      }
    }
  }
`;
type CurrentUser = {
  id: number;
  name: string;
  flags: { [key: string]: string };
};

type Data = {
  loading: boolean;
  currentUser: CurrentUser;
};

type Props = {
  children: any;
  currentUser: { [key: string]: string };
  data: Data;
};

// @ts-ignore
@graphql(currentUserQuery)
class CurrentUserProvider extends React.Component<Props> {
  static childContextTypes = {
    currentUser: PropTypes.object,
  };

  getChildContext() {
    if (!this.props.data.loading) {
      const user = {
        ...this.props.data.currentUser,
      };
      Utilities.localstoragePersist("CurrentUser", user);
      return {
        currentUser: {
          loading: false,
          ...user,
        },
      };
    }

    const user = Utilities.localstorageGet("CurrentUser");
    if (user) {
      return {
        currentUser: {
          loading: true,
          ...user,
        },
      };
    }

    return {
      currentUser: {
        loading: true,
        id: undefined,
        name: undefined,
        flags: undefined,
        isAdmin: undefined,
      },
    };
  }

  render() {
    return this.props.children;
  }
}

export default CurrentUserProvider;
