//@flow
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import { ApolloLink } from "apollo-link";
import { withClientState } from "apollo-link-state";
import { BatchHttpLink } from "apollo-link-batch-http";
import { createHttpLink } from "apollo-link-http";
import config from "./config";

const defaults = {
  networkStatus: {
    __typename: "NetworkStatus",
    isConnected: true,
  },
};

const resolvers = {
  Mutation: {},
};

const cache = new InMemoryCache();
const stateLink = withClientState({ resolvers, cache, defaults });
const graphQlToken = window.token ? `?token=${window.token}` : "";

export default () => {
  const httpLink = new BatchHttpLink({
    uri: config.API_URL + graphQlToken,
    credentials: "same-origin",
    batchInterval: 10,
    batchMax: 50,
  });

  return new ApolloClient({
    connectToDevTools: process.browser,
    link: ApolloLink.from([stateLink, httpLink]),
    cache,
  });
};
