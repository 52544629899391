/* eslint camelcase: 0 */
/* eslint func-names: 0 */
/* eslint no-use-before-define: 0 */
/* eslint no-var: 0 */
/* eslint no-unused-vars: 0 */
/* eslint no-extend-native: 0 */
/* eslint no-useless-escape: 0 */
/* eslint no-alert: 0 */
/* eslint import/first: 0 */
/* eslint no-undef: 0 */
/* eslint no-void: 0 */
/* eslint import/no-unresolved: 0 */
/* eslint import/extensions: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint no-empty: 0 */
/* eslint prefer-rest-params: 0 */

import * as React from "react";
import { Component } from "react";

import * as ReactDOM from "react-dom";
import { asyncComponent } from "react-async-component";

import BaseApp from "../init/BaseApp";

// legacy
import renderComponent from "../lib/renderComponent";
import jQuery from "jquery";

window.jQuery = jQuery;
window.$ = jQuery;
const $ = jQuery;

import Utilites from "Utilities";

import "react-credit-cards/es/styles-compiled.css";
require("jquery-next-id/jquery.nextid.js");
require("jquery-mouse-exit/jquery.mouseexit.js");
require("jquery-focus-exit/jquery.focusexit.js");

require("jquery-hover-flyout/jquery.hoverflyout.js");
require("jquery-focus-flyout/jquery.focusflyout.js");

require("jquery-tooltip/jquery.tooltip.js");

require("../../../../../inner_modules/datetimepicker/jquery.datetimepicker.full");

import "../../../../../inner_modules/datetimepicker/jquery.datetimepicker.min.css";

require("../../../../../public/Comus/inc/js/colpick");

const SendEmailSettings = asyncComponent({
  resolve: () => import("../components/JobLead/SendEmailSettings"),
});
const SendSMSSettings = asyncComponent({
  resolve: () => import("../components/JobLead/SendSMSSettings"),
});
const AssetPicker = asyncComponent({
  resolve: () => import("../components/Assets/AssetPicker"),
});
const SectionButton = asyncComponent({
  resolve: () => import("../components/Pages/Jobs/Shared/AddOldSectionButton"),
});
const AudienceToggle = asyncComponent({
  resolve: () => import("../components/Pages/Audience/AudienceToggle"),
});
const GlobalRecipientSearch = asyncComponent({
  resolve: () => import("../components/Recipient/GlobalRecipientSearch"),
});
const FindAndAddCompanyToContact = asyncComponent({
  resolve: () => import("../components/Recipient/FindAndAddCompanyToContact"),
});
const FindAndAddContactToCompany = asyncComponent({
  resolve: () => import("../components/Recipient/FindAndAddContactToCompany"),
});
const QuickNewOpportunitySettings = asyncComponent({
  resolve: () => import("../components/JobLead/QuickNewOpportunitySettings"),
});
const Articles = asyncComponent({
  resolve: () =>
    import("../components/Pages/Jobs/Emails/InputContent/Articles"),
});
const Products = asyncComponent({
  resolve: () =>
    import("../components/Pages/Jobs/Emails/InputContent/Products"),
});
const Buttons = asyncComponent({
  resolve: () => import("../components/Pages/Jobs/Emails/InputContent/Buttons"),
});

/// <reference path="typings/jquery/jquery.d.ts" />
/// <reference path="typings/jqueryui/jqueryui.d.ts" />
// @ts-ignore This somehow does not register?
Number.prototype.formatMoney = function (c, d, t) {
  c = Math.abs(c);
  c = isNaN(c) ? 2 : c;
  d = d == undefined ? "." : d;
  t = t == undefined ? "" : t;
  let n = this;
  const s = n < 0 ? "-" : "";
  const i = `${parseInt((n = Math.abs(+n || 0).toFixed(c)), 10)}`;
  const j = i.length > 3 ? i.length % 3 : 0;

  // @ts-ignore This is doing some strange stuff
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
};
let imagePickerTargetInput: jQuery;
let imagePickerTargetImageItem;
let imagePickerPopupPicker: jQuery = null;
let filePickerTargetInput: jQuery = null;
let filePickerPopupPicker: jQuery = null;
let fileChooser: jQuery;
let fileChooserCallback: string = "";
let fileUploadedCallback: string = "";
let fileFilecontext: any;

function detectIE() {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  const trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  const edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
}

function chunk(arr, len) {
  const chunks = [];
  let i = 0;
  const n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

function ArrayMax(array) {
  let max = 0;
  const a = array.length;
  for (let counter = 0; counter < a; counter++) {
    if (array[counter] > max) {
      max = array[counter];
    }
  }
  return max;
}
// @ts-ignore This is for legazy stuff
Array.chunk = chunk;
// @ts-ignore This is for legazy stuff
Array.max = ArrayMax;

function _GET() {
  const vars = {};
  const parts = window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => {
      vars[key] = value;
    }
  );
  return vars;
}
function input_validate_phone() {
  let s = $(this).val();
  s = s.replace(/[^0-9]/, "");
  $(this).val(s);
}

//Added as a quick fix for "xxx{0}".formatu('ble') not working
if (!String.prototype.format) {
  // @ts-ignore Legacy stuff
  String.prototype.format = function () {
    const args = arguments;
    const sprintfRegex = /\{(\d+)\}/g;
    const sprintf = function (match, number) {
      return number in args ? args[number] : match;
    };
    return this.replace(sprintfRegex, sprintf);
  };
}
//End Analytics
$(document).ready(() => {
  $("#input_content_all").on("click", "a.ajaxedit_input_content", function () {
    $(this).prop("clickHandled", true);
    const target = $(this).attr("target");
    $(target).attr("data-content", $(target).html()).load($(this).attr("href"));
    $(`${target}_panel`)
      .attr("data-content", $(`${target}_panel`).html())
      .html(
        `<a href="" class="silkbutton cancel ajaxcancel_input_content" title="Cancel" target="${target}"></a>`
      );
    return false;
  });
  $("#input_content_all").on("click", ".ajaxcancel_input_content", function () {
    $(this).prop("clickHandled", true);
    var target = $(this).attr("target");
    if (target == undefined) {
      target = `#${$(this).closest("td[id]").attr("id")}`;
    }
    $(target).html($(target).attr("data-content"));
    $(`${target}_panel`).html($(`${target}_panel`).attr("data-content"));
    return false;
  });
  $("#input_content_all").on("submit", "form.ajaxedit_input_content", function (
    event
  ) {
    const url = $(this).attr("action");
    const method = $(this).attr("method");
    const data = $(this).serialize();

    const request = {
      type: method,
      url,
      async: false,
      data,
    };

    const result = $.ajax(request);

    if (result.responseText == "1") {
      const ie = detectIE();
      if (ie === false) {
        window.location.reload(true);
      } else {
        window.location = window.location;
      }
    } else {
      alert(
        "There was an error executing your request. Please try again later or contact a webmaster"
      );
    }
    return false;
  });
  $("#content").on("click", "div.tabs a", tabs_click);
  $(".subtabs_menu a").click(sub_tab_click);

  //Make Rows clickable
  $("body").on("click", "tr.clickRow td", function (e) {
    if ($(this).hasClass("buttonGroup")) {
      return;
    }
    const link = $(this).parent().find(".clickable");
    if (link.length > 0 && e.which === 1) {
      link[0].click();
    } else if (link.length > 0 && e.which === 2) {
      window.open(link[0].href, "_blank");
    }
  });
  //Fixing double click ... because js is stupid
  $("body").on("click", "tr.clickRow > td a.clickable", function (e) {
    if (!$(this).is("[noFollow]")) {
      window.location = this.href;
    }
    return false;
  });
  $("input[type=submit]").dblclick(function (e) {
    e.preventDefault();
  });

  $(".rowSelector").click(function (e) {
    $(this).toggleClass("checked");
    return false;
  });
  $(".dataUrlable").click(function (event) {
    window.location = $(this).attr("data-url");
  });
  $("#content").removeAttr("unresolved");
  $("[loadingIndicator]").click(() => {
    enableLoadingOverlay();
  });
  $(".altText").each(function () {
    const $this = $(this);
    let text = $this.attr("title");
    const altContentId = $this.attr("altContentId");
    if (altContentId) {
      text = $(`#${altContentId}`).html();
    }
    $(this).tooltip({
      content: text,
      delay: 300,
    });
  });
  let skipLoading = false;
  $(document).on("click", 'a[target="_blank"]', () => {
    skipLoading = true;
  });
  $(document).on("submit", "form", () => {
    skipLoading = true;
  });
  $(document).on("submit", "a[download]", () => {
    skipLoading = true;
  });
  $(document).on("click", "a[skipload]", () => {
    skipLoading = true;
  });
  $(document).on("click", "a.skipload", () => {
    skipLoading = true;
  });
  window.onbeforeunload = function () {
    if (!skipLoading) {
      setTimeout(() => {
        enableLoadingOverlay();
      }, 800);
    }
    skipLoading = false;
  };
});
function tabs_click(event) {
  if (window.location.href.indexOf("recipients/files") > -1) {
    return true;
  }
  if ($(this).attr("href").indexOf("files") > -1) {
    return true;
  }
  $(this).addClass("selected").siblings().removeClass("selected");
  const page = $(this).attr("data-page");
  const page_obj = $(this)
    .parent()
    .siblings(".tabs_pages")
    .find(`.page[data-page=${page}]`);
  if (page_obj.length > 0) {
    $(page_obj).show().siblings(".page").hide();
  }
  const subtabs = $(`.subtabs_menu[data-page=${page}]`);
  if (subtabs.length > 0) {
    $(subtabs).show().siblings().hide();
  } else {
    $(".subtabs_menu").hide();
  }
  return false;
}
function sub_tab_click(e) {
  e.preventDefault();
  $(this).addClass("selected").siblings().removeClass("selected");
  const page = $(this).attr("data-subpage");
  const subpage = $(`.subpage[data-subpage="${page}"]`);
  if (subpage.length > 0) {
    $(subpage).show().siblings().hide();
  }
  return false;
}
function job_recipient_filter_refresh() {
  $("#filter_recipients").load(
    `/jobs/select_recipients_filter/${$(
      "#job_id"
    ).val()}/${job_recipient_filter_current_filter()}`,
    null,
    () => {
      $("a")
        .unbind("focus")
        .focus(function () {
          this.blur();
        });
    }
  );
}
function job_recipient_filter_remove() {
  const string = job_recipient_filter_current_filter();
  var removeString = $(this).attr("var");
  if (removeString.indexOf("vars") === 0) {
    const stringLength = removeString.length;
    removeString = `${removeString.substring(0, stringLength - 2)}|`;
  }
  $("#filter").val(string.replace(removeString, ""));
  job_recipient_filter_refresh();
  return false;
}
function job_recipient_filter_baseremove() {
  const string = job_recipient_filter_current_filter();
  $("#filter").val(string.replace($(this).attr("var"), ""));
  job_recipient_filter_refresh();
  return false;
}
function job_recipient_filter_add_vars() {
  $("#filter").val(
    `${job_recipient_filter_current_filter()}vars=${$(
      "select[name=filter_vars]"
    ).val()}|`
  );
  job_recipient_filter_refresh();
}
function job_recipient_filter_add_basevars() {
  $("#filter").val(
    `${job_recipient_filter_current_filter()}basevars=${$(
      "select[name=filter_basevars]"
    ).val()}|`
  );
  job_recipient_filter_refresh();
}
function job_recipient_filter_add_lists() {
  $("#filter").val(
    `${job_recipient_filter_current_filter()}list=${$(
      "select[name=filter_list]"
    ).val()}|`
  );
  job_recipient_filter_refresh();
}
function job_recipient_filter_current_filter() {
  let filter = $("#filter").val();
  if (filter.indexOf("|") === -1) {
    if ($("#filter").val() !== "") {
      $("#filter").val("");
    }
    return "";
  } else if (
    filter.indexOf("list") === -1 &&
    filter.indexOf("basevars") === -1 &&
    filter.indexOf("vars") === -1
  ) {
    if ($("#filter").val() !== "") {
      $("#filter").val("");
    }
    return "";
  }
  if (filter.charAt(filter.length - 1) !== "|") {
    filter += "|";
  }
  return filter;
}
function job_recipient_filter_edit_vars(obj) {
  let $obj = null;
  if (typeof obj == "string") {
    $obj = $(`#${obj}`);
  } else {
    $obj = $(this);
  }
  $("#popup").load(
    `/jobs/select_vals/${$obj.attr("var")}/${$obj.attr("vals")}`,
    null,
    popup_init
  );
  return false;
}
function job_recipient_filter_edit_basevars(obj) {
  let $obj = null;
  if (typeof obj == "string") {
    $obj = $(`#${obj}`);
  } else {
    $obj = $(this);
  }
  $("#popup").load(
    `/jobs/select_basevals/${encodeURIComponent(
      $obj.attr("var")
    )}/${encodeURIComponent($("#job_id").val())}/${encodeURIComponent(
      $obj.attr("vals")
    )}`,
    null,
    popup_init
  );
  return false;
}
//TODO: make something else instead of those popups
function popup_init() {
  const popup = $("#popup");
  popup.show();
  popup
    .css("height", `${popup.children().height() + 20}px`)
    .css("left", `${($(window).width() - popup.width()) / 2}px`)
    .css("top", `${($(window).height() - popup.height()) / 2}px`);
}
function popup_close() {
  $("#popup").html("").hide();
}

function imagepicker_open(field) {
  window.open(
    `/images/popup/${field}`,
    "",
    "location=1,status=1,scrollbars=1,width=530,height=500"
  );
}
function imagepicker_pick(id, src, field) {
  $(window.opener.document.getElementById(field))
    .find("input[type=hidden]")
    .val(id);
  $(window.opener.document.getElementById(field)).find("img").attr("src", src);
  window.close();
  return false;
}
//end TODO
function job_recipient_filter_edit_vars_save() {
  const vals = [];
  const vars = $(this).attr("var");
  $("#edit_vars")
    .find("input[type=checkbox]")
    .each(function () {
      if (this.checked) {
        vals.push($(this).val());
      }
    });
  const vals_string = vals.join(",");
  const str = job_recipient_filter_current_filter();
  const regexp = new RegExp(`vars=${vars}[-1234567890,]*`);
  $("#filter").val(
    str.replace(regexp, `vars=${vars}${vals_string ? `-${vals_string}` : ""}`)
  );
  popup_close();
  job_recipient_filter_refresh();
}
function job_recipient_filter_edit_basevars_save() {
  const vals = [];
  const vars = $(this).attr("var");
  $("#edit_vars")
    .find("input[type=checkbox]")
    .each(function () {
      if (this.checked) {
        const newValue = $(this).val();
        vals.push(newValue);
      }
    });
  const joinedBasevarComponents = vals.join(",");
  const oldFilter = job_recipient_filter_current_filter();
  const regexp = new RegExp(`basevars=${vars}[|-][A-Za-z_0-9, -]*[|]?`);
  const affix = joinedBasevarComponents ? `-${joinedBasevarComponents}` : "";
  const replaceTargetContent = `basevars=${vars}${affix}|`;
  const newFilter = oldFilter.replace(regexp, replaceTargetContent);
  $("#filter").val(newFilter);
  popup_close();
  job_recipient_filter_refresh();
}
function feedback_close() {
  $("#feedback").fadeOut(100);
}
function iframe_submit() {
  $(`iframe[name=${$(this).attr("target")}]`)
    .attr("callback", $(this).attr("callback"))
    .unbind("load")
    .bind("load", window[$(this).attr("callback")]);
  return true;
}
function confirm_link() {
  const confirmText = $(this).attr("confirm");
  const confirmation = confirm(confirmText);
  if (!confirmation) {
    return false;
  }
  const callback = $(this).attr("complete-callback");
  const fn = window[callback];
  if ($(this).is("[overlay]")) {
    enableLoadingOverlay();
  }
  if (callback !== undefined && typeof fn === "function") {
    enableLoadingOverlay();
    const $theLink = $(this);
    $.ajax({
      url: $(this).attr("href"),
    }).done((e) => {
      disableLoadingOverlay();
      fn($theLink);
    });
    return false;
  }
  return true;
}
function ajaxUploadFeedbackDisplayer(file, response) {
  alert(response);
}
$(document).ready(() => {
  $("a")
    .unbind("focus")
    .focus(function () {
      this.blur();
    });
  $("a.confirm").click(confirm_link);
  window.bindColorPickers();
  if (!window.File || !window.FileReader || !window.FileList || !window.Blob) {
    $("body").html(
      '<h1 style="margin:100px auto 0 auto;text-align: center; color: #eee;">Your browser doesn\'t Support features used by the system.<br>Please get an up to date browser and try again</h1>'
    );
  }
  $(".image_upload_button").click(FileUploadHandler);
  $(".CheckAllInTable").click(function () {
    $(this)
      .closest("table")
      .find('tbody input[type="checkbox"]')
      .prop("checked", $(this).prop("checked"));
  });
  $(".bon-hud-counter.clickable").click(function (e) {
    const anchor = $(this).find("a")[0];
    if (e.target !== anchor) {
      anchor.click();
    }
  });
  $(".bhCounter.clickable").click(function (e) {
    const anchor = $(this).find("a")[0];
    if (e.target !== anchor) {
      anchor.click();
    }
  });
});
$.fn.extend({
  insertAtCaret(myValue) {
    return this.each(function (i) {
      if (document.selection) {
        //For browsers like Internet Explorer
        this.focus();
        // @ts-ignore This should work in legacy browsers
        const sel = document.selection.createRange();
        sel.text = myValue;
        this.focus();
      } else if (this.selectionStart || this.selectionStart == "0") {
        //For browsers like Firefox and Webkit based
        const startPos = this.selectionStart;
        const endPos = this.selectionEnd;
        this.value =
          this.value.substring(0, startPos) +
          myValue +
          this.value.substring(endPos, this.value.length);
        this.focus();
        this.selectionStart = startPos + myValue.length;
        this.selectionEnd = startPos + myValue.length;
      } else {
        this.value += myValue;
        this.focus();
      }
    });
  },
});
$(document).ready(() => {
  $(".requestNdelete").unbind("click");
  $("#content").on("click", ".requestNdelete", requestNdelete);
  $("<div>", {
    class: "loadingOverlay",
  })
    .appendTo($("body"))
    .hide()
    .append(
      $("<div>", { class: "loadingIndicator" }).append(
        $("<div>", { class: "loadingAnimation" })
      )
    );

  dynamic_procidure_entry_handler();

  $(".clickToggleHide").click(function () {
    $(this).toggleClass("hide");
  });
  $(".clickToggleHideSiblings").click(function () {
    $(this).toggleClass("hideSiblings");
  });
  $(".user_preview_id").click(function (e) {
    e.preventDefault();
    const userid = $(this).attr("data-id");
    const jobid = $(this).attr("data-job");
    $.ajax({
      url: `/jobs/action_send_preview_user/${jobid}/${userid}`,
      complete(data) {
        alert("Preview is now in queue");
      },
    });
  });
});
function enableLoadingOverlay() {
  $(".loadingOverlay").show();
}
function disableLoadingOverlay() {
  $(".loadingOverlay").hide();
}
function requestNdelete(event) {
  event.preventDefault();
  if ($(this).hasClass("confirm")) {
    let confirmText = $(this).attr("confirm");
    if (confirmText == undefined || confirmText.length < 3) {
      confirmText = `Are you shure you want to ${$(this).attr(
        "data-entry-title"
      )}`;
    }
    if (!confirm(confirmText)) {
      return false;
    }
  }
  const parent = $(this).closest("tr");
  $.ajax({
    url: this.href,
    complete() {
      parent.remove();
      disableLoadingOverlay();
    },
    beforeSend() {
      enableLoadingOverlay();
    },
    error() {
      disableLoadingOverlay();
    },
  });
  return false;
}
function dynamic_procidure_entry_handler() {
  $("#content").on("click", ".dynamic_procidure_entry a", function (event) {
    event.preventDefault();
    const item = $(this).closest(".dynamic_procidure_entry");
    const parent = item.parent();
    // @ts-ignore Legacy cracyness
    if (recipientEmail == undefined || recipientEmail.length < 6) {
      let confirmMessage =
        "This individual is missing an email address. Are you sure you want to continue?";
      // @ts-ignore Legacy cracyness
      if (
        recipientEmailMissingMesage != undefined &&
        recipientEmailMissingMesage.length > 5
      ) {
        confirmMessage = recipientEmailMissingMesage;
      }
      if (!confirm(confirmMessage)) {
        return;
      }
    }
    $.ajax({
      url: this.href,
      complete(data) {
        disableLoadingOverlay();
        parent.html(data.responseText);
      },
      beforeSend() {
        enableLoadingOverlay();
      },
      error() {
        disableLoadingOverlay();
      },
    });
  });
}

window.CKEDITOR.config.pasteFromWordRemoveStyles = false;
window.CKEDITOR.config.pasteFromWordRemoveFontStyles = false;
window.CKEDITOR.config.forceSimpleAmpersand = true;
window.CKEDITOR.config.entities = false;
window.CKEDITOR.config.enterMode = window.CKEDITOR.ENTER_DIV;
window.CKEDITOR.config.line_height =
  "14px;15px;16px;17px;18px;19px;20px;21px;22px;23px;24px;25px;26px;27px;28px;29px;30px;32px;34px;36px;38px;40px;42px;44px;46px;48px;50px;52px;54px;56px;58px;60px;62px;64px;66px;68px;70px";
window.CKEDITOR.config.fontSize_sizes =
  "8px;9px;10px;11px;12px;13px;14px;15px;16px;17px;18px;19px;20px;21px;22px;23px;24px;25px;26px;27px;28px;29px;30px;32px;34px;36px;38px;40px;42px;44px;46px;48px;50px;52px;54px;56px;58px;60px;62px;64px;66px;68px;70px;72px;74px;76px;78px;80px;90px;100px";
window.CKEDITOR.config.removePlugins = "base64image";

window.ckeditorConfig = {
  on: {
    instanceReady(ev) {
      this.dataProcessor.htmlFilter.onText = function (settings, text) {
        //find all bits in curly brackets
        const matches = text.match(/\{([^}]+)\}/g);
        //go through each match and replace the encoded characters
        if (matches != null) {
          for (const match in matches) {
            let replacedString = matches[match];
            replacedString = matches[match].replace(/&gt;/g, ">");
            replacedString = replacedString.replace(/&lt;/g, "<");
            replacedString = replacedString.replace(/&amp;/g, "&");
            text = text.replace(matches[match], replacedString);
          }
        }
        return text;
      };
      this.dataProcessor.writer.setRules("p", {
        indent: false,
        breakBeforeOpen: false,
        breakAfterOpen: false,
        breakBeforeClose: false,
        breakAfterClose: false,
      });
    },
    mode(ev) {
      let str = ev.editor.getData();
      str = str
        .replace(/&amp;/g, "&")
        .replace(/&gt;/g, ">")
        .replace(/&lt;/g, "<")
        .replace(/&quot;/g, '"');
      ev.editor.element.setValue(str);
    },
  },
};

function makeCKEditor(elementId) {
  window.CKEDITOR.replace(elementId, {
    on: {
      instanceReady(ev) {
        this.dataProcessor.htmlFilter.onText = function (settings, text) {
          //find all bits in curly brackets
          const matches = text.match(/\{([^}]+)\}/g);
          //go through each match and replace the encoded characters
          if (matches != null) {
            for (const index in matches) {
              const inputString = matches[index];

              if (
                typeof inputString === "string" ||
                inputString instanceof String
              ) {
                let replacedString = inputString;

                replacedString = replacedString.replace(/&gt;/g, ">");
                replacedString = replacedString.replace(/&lt;/g, "<");
                replacedString = replacedString.replace(/&amp;/g, "&");

                text = text.replace(inputString, replacedString);
              }
            }
          }
          return text;
        };
        window.CKEDITOR.instances[elementId].on("change", (ev) => {
          window.CKEDITOR.instances[elementId].updateElement();

          let str = ev.editor.getData();
          str = str
            .replace(/&amp;/g, "&")
            .replace(/&gt;/g, ">")
            .replace(/&lt;/g, "<")
            .replace(/&quot;/g, '"');
          const theUberParentElement = $(`#${elementId}`);

          theUberParentElement.val(str);
        });
        this.dataProcessor.writer.setRules("p", {
          indent: false,
          breakBeforeOpen: false,
          breakAfterOpen: false,
          breakBeforeClose: false,
          breakAfterClose: false,
        });
      },
      mode(ev) {
        let str = ev.editor.getData();
        str = str
          .replace(/&amp;/g, "&")
          .replace(/&gt;/g, ">")
          .replace(/&lt;/g, "<")
          .replace(/&quot;/g, '"');

        ev.editor.element.setValue(str);
      },
    },
  });
}

function input_numeric_only(e) {
  const allowed_keys = [
    9,
    13,
    188,
    8,
    46,
    37,
    39,
    67,
    86,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
    45,
    35,
    36,
  ];
  if (
    (e.keyCode < 48 || e.keyCode > 57) &&
    allowed_keys.indexOf(e.keyCode) == -1
  ) {
    e.preventDefault();
  }
}
function input_currency_blur(e) {
  let i = this.value.replace(/[^\d\,]/g, "");
  i = Number(i.replace(/\,/g, "."));
  if (!i) {
    i = 0;
  }
  // @ts-ignore Legacy stuff
  let s = i.formatMoney(0, ",", ".");
  if (s.slice(-2, -1) == ",") {
    s += "0";
  }
  if (s.slice(-3, -1) != ",") {
    s += ",-";
  }
  this.value = s;
}
function input_currency_focus(e) {
  this.value = this.value.replace(/[^\d\,]/g, "");
  if (this.value.slice(-1) == ",") {
    this.value = this.value.slice(0, -1);
  }
}
function input_percentage_blur(e) {
  let i = this.value.replace(/[^\d\,]/g, "");
  i = Number(i.replace(/\,/g, "."));
  if (!i) {
    i = 0;
  }
  // @ts-ignore Legacy stuff
  let s = i.formatMoney(0, ",", ".");
  if (s.slice(-2, -1) == ",") {
    s += "0";
  }
  this.value = `${s}%`;
}
function input_percentage_focus(e) {
  this.value = this.value.replace(/[^\d\,]/g, "");
  if (this.value.slice(-1) == ",") {
    this.value = this.value.slice(0, -1);
  }
}
const imagePickerUpdateImage = function (
  item: jQuery,
  imageId: number,
  width,
  height
) {
  if (width === void 0) {
    width = 150;
  }
  if (height === void 0) {
    height = 100;
  }
  item.attr("data-id", imageId);
  item
    .find("img")
    .attr("src", `/Assets/ImageCropped/${imageId}/${width}/${height}`);
  const name = item.find(".name");
  name.html("");
  $.getJSON(`/Assets/ImageMetadata/${imageId}`, (data) => {
    name.html(data.title);
    item.attr("title", data.title);
  });
};
$(document).ready(() => {
  // Radialize the colors
  if (typeof window.Highcharts !== "undefined") {
    window.Highcharts.getOptions().colors = window.Highcharts.map(
      window.Highcharts.getOptions().colors,
      window.highChartifyColor
    );
    window.Highcharts.setOptions({ lang: { thousandsSep: "." } });
  }
  $("input.input_numeric_only").keydown(input_numeric_only);
  $("input.input_currency")
    .blur(input_currency_blur)
    .trigger("blur")
    .focus(input_currency_focus);
  $("input.input_percentage")
    .blur(input_percentage_blur)
    .trigger("blur")
    .focus(input_percentage_focus);

  let imagePickerPopupPickerTemplate = $("#image_picker_contentTemplate");
  if (imagePickerPopupPickerTemplate.length > 0) {
    const changePicker = function (popupPicker: jQuery) {
      popupPicker.find(".image_upload_button").click(FileUploadHandler);
      (function () {
        const images = popupPicker.find("img");
        const imgCount = images.length;
        let current = 0;
        var looper = function () {
          const element = $(images[current++]);
          const src = element.attr("data-src");
          if (src) {
            element.attr("src", src);
          }
          if (current < imgCount) {
            setTimeout(looper, 10);
          }
        };
        looper();
      })();
      popupPicker.show();
    };
    imagePickerPopupPicker = $(imagePickerPopupPickerTemplate.html());

    $("#input_content_all")
      .on("click", ".image_picker", function () {
        imagePickerPopupPicker.appendTo($("body"));
        const parent = $(this).parent().parent();
        imagePickerTargetInput = parent.children("input");
        imagePickerTargetImageItem = parent.find(".item");
        changePicker(imagePickerPopupPicker);
      })
      .on("click", ".item.image_picker .deleteButton", function (event) {
        event.preventDefault();
        event.stopPropagation();
        const item = $(this).closest(".item");
        item.find("img").attr("src", "");
        item.find(".name").html("");
        item.attr("data-id", "");
        item.parent().siblings("input").attr("value", "");
      });

    imagePickerPopupPicker
      .on("click", ".image_picker_pick", function (event) {
        event.stopPropagation();
        event.preventDefault();
        const imageId = $(this).attr("imageId");
        imagePickerTargetInput.attr("value", imageId);
        imagePickerUpdateImage(imagePickerTargetImageItem, imageId);
        imagePickerPopupPicker.hide();
      })
      .on("click", ".image_container", (event) => {
        event.stopPropagation();
      })
      .click(function (event) {
        event.preventDefault();
        $(this).hide();
      });

    imagePickerPopupPicker.on("click", ".directory_pick", function (event) {
      const folder = $(this);
      const folderId = folder.attr("data-id");
      if (folderId) {
        $.ajax({
          url: `/jobs/picker/${folderId}`,
          success(data) {
            imagePickerPopupPickerTemplate = $(data);
            const tempPicker = $(imagePickerPopupPickerTemplate.html());
            tempPicker;
            imagePickerPopupPicker.html(tempPicker.html());
            changePicker(imagePickerPopupPicker);
          },
        });
      }
    });
    imagePickerPopupPicker.on("click", ".ImagePickerBackButton", function (
      event
    ) {
      const button = $(this);
      const parentId = button.attr("parent");
      if (parentId !== "") {
        $.ajax({
          url: `/jobs/picker/${parentId}`,
          success(data) {
            imagePickerPopupPickerTemplate = $(data);
            const tempPicker = $(imagePickerPopupPickerTemplate.html());
            imagePickerPopupPicker.html(tempPicker.html());
            changePicker(imagePickerPopupPicker);
          },
        });
      }
    });
  }

  let filePickerPopupPickerTemplate = $("#file_picker_contentTemplate");
  if (filePickerPopupPickerTemplate.length > 0) {
    let jobsaleTargetHiddenInput;
    let jobsaleTargetFilenameDiv;
    const changeFilePicker = function (popupPicker) {
      popupPicker.find(".image_upload_button").click(FileUploadHandler);
      (function () {
        const images = popupPicker.find("img");
        const imgCount = images.length;
        let current = 0;
        var looper = function () {
          const element = $(images[current++]);
          const src = element.attr("data-src");
          if (src) {
            element.attr("src", src);
          }
          if (current < imgCount) {
            setTimeout(looper, 10);
          }
        };
        looper();
      })();
      popupPicker.show();
    };
    filePickerPopupPicker = $(filePickerPopupPickerTemplate.html());
    $("#input_content_all").on("click", ".file_picker", function () {
      filePickerPopupPicker.appendTo($("body"));
      const $this = $(this);
      const parent = $this.parent();
      const grandparent = parent.parent();
      filePickerTargetInput = grandparent.children("input");
      jobsaleTargetHiddenInput = parent.children("input[type=hidden]");
      jobsaleTargetFilenameDiv = parent.children("div.name");
      changeFilePicker(filePickerPopupPicker);
    });
    filePickerPopupPicker
      .on("click", ".file_pick", function (event) {
        event.stopPropagation();
        event.preventDefault();
        const filePath = $(this).attr("data-path");
        const fileTitle = $(this).attr("title");
        const fileId = $(this).attr("data-id");
        filePickerTargetInput.attr("value", filePath);
        jobsaleTargetHiddenInput.val(fileId);
        jobsaleTargetFilenameDiv.html(fileTitle);
        filePickerPopupPicker.hide();
      })
      .on("click", ".image_container", (event) => {
        event.stopPropagation();
      })
      .click(function (event) {
        event.preventDefault();
        $(this).hide();
      });
    filePickerPopupPicker.on("click", ".directory_pick", function (event) {
      event.stopPropagation();
      event.preventDefault();
      const folder = $(this);
      const folderId = folder.attr("data-id");
      if (folderId) {
        $.ajax({
          url: `/jobs/FilePicker/${folderId}`,
          success(data) {
            filePickerPopupPickerTemplate = $(data);
            const tempPicker = $(filePickerPopupPickerTemplate.html());
            filePickerPopupPicker.html(tempPicker.html());
            changeFilePicker(filePickerPopupPicker);
          },
        });
      }
    });
    filePickerPopupPicker.on("click", ".ImagePickerBackButton", function (
      event
    ) {
      const button = $(this);
      const parentId = button.attr("parent");
      if (parentId !== "") {
        $.ajax({
          url: `/jobs/FilePicker/${parentId}`,
          success(data) {
            filePickerPopupPickerTemplate = $(data);
            const tempPicker = $(filePickerPopupPickerTemplate.html());
            filePickerPopupPicker.html(tempPicker.html());
            changeFilePicker(filePickerPopupPicker);
          },
        });
      }
    });
  }
});
const transliteration_map = {
  // Latin
  À: "A",
  Á: "A",
  Â: "A",
  Ã: "A",
  Ä: "A",
  Å: "A",
  Æ: "Ae",
  Ç: "C",
  È: "E",
  É: "E",
  Ê: "E",
  Ë: "E",
  Ì: "I",
  Í: "I",
  Î: "I",
  Ï: "I",
  Ð: "D",
  Ñ: "N",
  Ò: "O",
  Ó: "O",
  Ô: "O",
  Õ: "O",
  Ö: "O",
  Ő: "O",
  Ø: "O",
  Ù: "U",
  Ú: "U",
  Û: "U",
  Ü: "U",
  Ű: "U",
  Ý: "Y",
  Þ: "Th",
  ß: "ss",
  à: "a",
  á: "a",
  â: "a",
  ã: "a",
  ä: "a",
  å: "a",
  æ: "ae",
  ç: "c",
  è: "e",
  é: "e",
  ê: "e",
  ë: "e",
  ì: "i",
  í: "i",
  î: "i",
  ï: "i",
  ð: "d",
  ñ: "n",
  ò: "o",
  ó: "o",
  ô: "o",
  õ: "o",
  ö: "o",
  ő: "o",
  ø: "o",
  ù: "u",
  ú: "u",
  û: "u",
  ü: "u",
  ű: "u",
  ý: "y",
  þ: "th",
  ÿ: "y",
  // Latin symbols
  "©": "(c)",
  // Greek
  Α: "A",
  Β: "B",
  Γ: "G",
  Δ: "D",
  Ε: "E",
  Ζ: "Z",
  Η: "H",
  Θ: "8",
  Ι: "I",
  Κ: "K",
  Λ: "L",
  Μ: "M",
  Ν: "N",
  Ξ: "3",
  Ο: "O",
  Π: "P",
  Ρ: "R",
  Σ: "S",
  Τ: "T",
  Υ: "Y",
  Φ: "F",
  Χ: "X",
  Ψ: "PS",
  Ω: "W",
  Ά: "A",
  Έ: "E",
  Ί: "I",
  Ό: "O",
  Ύ: "Y",
  Ή: "H",
  Ώ: "W",
  Ϊ: "I",
  Ϋ: "Y",
  α: "a",
  β: "b",
  γ: "g",
  δ: "d",
  ε: "e",
  ζ: "z",
  η: "h",
  θ: "8",
  ι: "i",
  κ: "k",
  λ: "l",
  μ: "m",
  ν: "n",
  ξ: "3",
  ο: "o",
  π: "p",
  ρ: "r",
  σ: "s",
  τ: "t",
  υ: "y",
  φ: "f",
  χ: "x",
  ψ: "ps",
  ω: "w",
  ά: "a",
  έ: "e",
  ί: "i",
  ό: "o",
  ύ: "y",
  ή: "h",
  ώ: "w",
  ς: "s",
  ϊ: "i",
  ΰ: "y",
  ϋ: "y",
  ΐ: "i",
  // Turkish
  Ş: "S",
  İ: "I",
  Ğ: "G",
  ş: "s",
  ı: "i",
  ğ: "g",
  // Russian
  А: "A",
  Б: "B",
  В: "V",
  Г: "G",
  Д: "D",
  Е: "E",
  Ё: "Yo",
  Ж: "Zh",
  З: "Z",
  И: "I",
  Й: "J",
  К: "K",
  Л: "L",
  М: "M",
  Н: "N",
  О: "O",
  П: "P",
  Р: "R",
  С: "S",
  Т: "T",
  У: "U",
  Ф: "F",
  Х: "H",
  Ц: "C",
  Ч: "Ch",
  Ш: "Sh",
  Щ: "Sh",
  Ъ: "",
  Ы: "Y",
  Ь: "",
  Э: "E",
  Ю: "Yu",
  Я: "Ya",
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "yo",
  ж: "zh",
  з: "z",
  и: "i",
  й: "j",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "h",
  ц: "c",
  ч: "ch",
  ш: "sh",
  щ: "sh",
  ъ: "",
  ы: "y",
  ь: "",
  э: "e",
  ю: "yu",
  я: "ya",
  // Ukrainian
  Є: "Ye",
  І: "I",
  Ї: "Yi",
  Ґ: "G",
  є: "ye",
  і: "i",
  ї: "yi",
  ґ: "g",
  // Czech
  Č: "C",
  Ď: "D",
  Ě: "E",
  Ň: "N",
  Ř: "R",
  Š: "S",
  Ť: "T",
  Ů: "U",
  Ž: "Z",
  č: "c",
  ď: "d",
  ě: "e",
  ň: "n",
  ř: "r",
  š: "s",
  ť: "t",
  ů: "u",
  ž: "z",
  // Polish
  Ą: "A",
  Ć: "C",
  Ę: "e",
  Ł: "L",
  Ń: "N",
  Ś: "S",
  Ź: "Z",
  Ż: "Z",
  ą: "a",
  ć: "c",
  ę: "e",
  ł: "l",
  ń: "n",
  ś: "s",
  ź: "z",
  ż: "z",
  // Latvian
  Ā: "A",
  Ē: "E",
  Ģ: "G",
  Ī: "i",
  Ķ: "k",
  Ļ: "L",
  Ņ: "N",
  Ū: "u",
  ā: "a",
  ē: "e",
  ģ: "g",
  ī: "i",
  ķ: "k",
  ļ: "l",
  ņ: "n",
  ū: "u",
};
function transliterate(text) {
  for (const k in transliteration_map) {
    text = text.replace(RegExp(k, "g"), transliteration_map[k]);
  }
  return text;
}

function InternetExplorerVersion() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }
  return null;
}
function renderAnswersPie(elementId, pieData) {
  let total = 0;
  for (const key in pieData) {
    const data = pieData[key];
    total += data[1];
  }
  if (total === 0) {
    for (const key in pieData) {
      pieData[key][1] = 1;
    }
  }
  const chart = new window.Highcharts.Chart({
    chart: {
      renderTo: elementId,
      backgroundColor: "transparent",
      plotBorderWidth: null,
      plotShadow: true,
      margin: [5, 0, 0, 0],
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    legend: {
      width: 100,
      align: "left",
      itemWidth: 100,
      verticalAlign: "top",
      x: 0,
      y: 0,
    },
    title: {
      text: "",
    },
    credits: { enabled: false },
    tooltip: {
      formatter() {
        return `${this.point.name} <b>${window.Highcharts.numberFormat(
          this.percentage,
          2
        )}%</b>`;
      },
      percentageDecimals: 1,
    },
    plotOptions: {
      pie: {
        size: "100%",
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        type: "pie",
        data: pieData,
      },
    ],
  });
}
const bindColorPickers = function () {
  $(".colorPicker").each(function () {
    const element = $(this);
    if (element.attr("data-initialized")) {
      return;
    }

    element.attr("data-initialized", true);
    const parent = element.parent();
    if (!parent.hasClass("colorPickerWrapper")) {
      parent.addClass("colorPickerWrapper");
    }

    element.parent().css("border-left-color", `#${this.value}`);
    element
      .colpick({
        layout: "rgbhex",
        submit: 0,
        color: this.value,
        colorScheme: "dark",
        onChange(hsb, hex, rgb, el, bySetColor) {
          $(el).parent().css("border-left-color", `#${hex}`);
          // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
          if (!bySetColor) {
            $(el).val(hex);
          }
        },
      })
      .keyup(function () {
        $(this).colpickSetColor(this.value);
      });
  });
  $("#section_description_rich_text_editor_enalble").click((e) => {
    makeCKEditor("section_description_rich_text_editor");
    e.preventDefault();
    return false;
  });

  $("#section_description2_rich_text_editor_enalble").click((e) => {
    makeCKEditor("section_description2_rich_text_editor");
    e.preventDefault();
    return false;
  });
};
function update_connection(rcmId, positionId, userId, callback) {
  $.ajax({
    url: `/recipients/ajax_update_connection/${rcmId}/${positionId}/${userId}`,
    success(data) {
      if (callback) {
        callback(data);
      }
    },
  });
}

// @TODO: Fix translation
function InputContentImagePickerUploadComplete(file, response) {
  const imageId = parseInt(response, 10);
  if (isNaN(imageId)) {
    alert("Had some trouble uploading your file.\n\nPlease try again.");
  } else {
    imagePickerTargetInput.attr("value", imageId);
    imagePickerUpdateImage(imagePickerTargetImageItem, response);
    imagePickerPopupPicker.hide();
  }
}
// @TODO: Fix translation
function InputContentFilePickerUploadComplete(file, response) {
  const imageId = parseInt(response, 10);
  if (isNaN(imageId)) {
    alert("Had some trouble uploading your file.\n\nPlease try again.");
  } else {
    $.getJSON(`/Assets/FileMetadata/${imageId}`, (data) => {
      filePickerTargetInput.attr("value", data.publicLink);
    });
    filePickerPopupPicker.hide();
  }
}
function FileUploadHandler(event, ref) {
  event.preventDefault();
  event.stopPropagation();
  instanciateFileChooser();

  let buttonReference = ref ? $(ref) : $(this);

  let fileType = buttonReference.attr("data-file-type");

  if (fileType !== undefined) {
    fileChooser.attr("accept", fileType);
  }

  fileChooserCallback = buttonReference.attr("action-callback-url");
  console.log(fileChooserCallback);
  fileUploadedCallback = buttonReference.attr("complete-callback");
  fileChooser.click();
}
function instanciateFileChooser() {
  if (fileChooser === undefined) {
    fileChooser = $(
      '<input type="file" style="visability:hidden;" accept="image/*" multiple>'
    );
    fileChooser.change(fileChooserHandleFiles);
  }
}
function fileChooserHandleFiles() {
  const fileList = this.files;
  if (fileList.length < 0) {
    return;
  }
  uploadFiles(fileList);
}
function uploadFiles(files) {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    uploadFile(file);
  }
}
function uploadFile(file) {
  fileFilecontext = file;
  const reader = new FileReader();
  reader.onload = function (event) {
    //Send the file
    const fileInformation = {
      data: event.target.result,
      name: file.name,
      size: file.size,
      type: file.type,
    };
    const xhr = new XMLHttpRequest();
    xhr.open("put", fileChooserCallback, true);
    // Set appropriate headers
    xhr.setRequestHeader("Content-Type", "multipart/form-data");
    xhr.onload = fileUploaded;
    xhr.send(JSON.stringify(fileInformation));
  };
  reader.readAsDataURL(file);
}
function fileUploaded(event: Event, data, ble) {
  const fn = window[fileUploadedCallback];
  if (typeof fn === "function") {
    // @ts-ignore This does work ... donno why flow is nog liking it
    fn(fileFilecontext, event.target.response);
  }
}
(function () {
  let messageId = 0;
  let currentNotifications = localstorageGetNotifications();
  let UnreadNotificationCount = 0;
  const notificationLoadInterval = 30 * 1000;
  const notificationFirstLoadTimeout = 5 * 1000;
  const animationTime = 100;
  function DisplayNotificationExtended(id) {
    hideMessages();
    const popup = $("#messages_popup");
    popup.html("");

    let message: null | {
      icon: string;
      createdAt: string;
      title: string;
      subtitle: string;
      body: string;
    } = null;
    if (currentNotifications.forEach) {
      currentNotifications.forEach((item) => {
        if (item.id == id) {
          message = item;
        }
      });
    }
    if (!message) {
      return;
    }
    popup.append(
      $("<div>", {
        class: `${message.icon} notifications_popup_icon`,
      })
    );
    popup.append(
      $("<div>", {
        class: "notifications_datetime_message",
        html: message.createdAt,
      })
    );
    popup.append(
      $("<div>", {
        class: "notifications_popup_title",
        html: message.title,
      })
    );
    popup.append(
      $("<div>", {
        class: "notifications_popup_subtitle",
        html: message.subtitle,
      })
    );
    popup.append(
      $("<div>", {
        class: "notifications_popup_message",
        html: message.body,
      })
    );
    if (!message.openedAt) {
      $.ajax({
        url: `/notifications/MarkRead/${id}`,
      });
    }
    setTimeout(() => {
      $("#cover").show();
      popup.show(animationTime);
    }, animationTime);
  }

  function updateOrder(element, path) {
    const responses = element.find(".button.big");
    const jobId = element.find(".dragarrowicon").attr("job");
    const responseIds = [];

    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      responseIds.push(response.dataset.response);
    }
    $.ajax({
      url: path + jobId,
      type: "POST",
      data: JSON.stringify(responseIds),
    });
  }

  $(document).ready(() => {
    if ($("#NotificationWrapper").length < 1) {
      return;
    }
    UpdateNotificationDisplay();
    setTimeout(() => {
      setInterval(notificationsUpdateFromServer, notificationLoadInterval);
      notificationsUpdateFromServer();
    }, notificationFirstLoadTimeout);
    setInterval(() => {
      messageId = 0;
    }, 1005000);
    $("#NotificationWrapper").on("click", ".message", function (event) {
      event.preventDefault();
      event.stopPropagation();
      const id = $(this).data("id");
      DisplayNotificationExtended(id);
    });
    $("#NotificationsAlarmIcon").on("click", (event) => {
      event.preventDefault();
      event.stopPropagation();
      if ($("#NotificationWrapper").is(":visible")) {
        hideMessages();
      } else {
        displayNotifications();
      }
    });
    $(document).click((event) => {
      hideMessages();
    });

    let lang = "en";

    if (window.currentLangugage === "is_IS") {
      lang = "is";
    }

    $(".dateTimePicker").datetimepicker({
      lang,
      step: 5,
      format: "d/m/Y H:i",
    });

    $(".datePicker").datetimepicker({
      lang,
      timepicker: false,
      step: 5,
      format: "d/m/Y",
    });
    $("#globalSearchInput")
      .focusin(() => {
        $("#globalSearch").addClass("focused");
      })
      .focusout(() => {
        $("#globalSearch").removeClass("focused");
      });

    $("#feedback")
      .css("left", `${($(window).width() - 100) / 2}px`)
      .css("top", `${($(window).height() - 100) / 2}px`);

    //job_lead manage_responses
    $("#availableButtonsJobLead").on("change", ".checkboxButton", function () {
      const element = $(this);
      const responseId = element.attr("value");
      const jobId = element.attr("job");
      const color = element.attr("color");
      const title = element.attr("buttonTitle");
      const indexCount = $("#buttonTable").find("tr").length;

      if (element.is(":checked")) {
        const row = `<tr id="response_${responseId}" class="magicalButtonMoveRow buttonRow buttonNumber-${responseId}"><td style="vertical-align: top;"><a job="${jobId}" response="${responseId}" class="dragarrowicon" style="margin-right: 5px;" title="[[Drag]]"><i class="orange" style="font-size: 50px;"></i></a></td><td><a class="button play big ${color}" data-response="${responseId}" >&nbsp;${title}&nbsp;</a></td></tr>`;
        $("#buttonTable").append(row);
        $.ajax({
          url: `/job_lead/add_button_to_job/${jobId}/${responseId}/${indexCount}`,
          success() {
            updateOrder($("#call_responses_order"), "/job_lead/update_order/");
          },
        });
      } else {
        $(`#response_${responseId}`).remove();
        $.ajax({
          url: `/job_lead/remove_button_from_job/${jobId}/${responseId}`,
          success() {
            updateOrder($("#call_responses_order"), "/job_lead/update_order/");
          },
        });
      }
    });

    $("#call_responses_order").sortable({
      axis: "y",
      update() {
        updateOrder($(this), "/job_lead/update_order/");
      },
    });

    //job_sale select_responses
    $("#availableButtonsJobSale").on("change", ".checkboxButton", function () {
      const element = $(this);
      const responseId = element.attr("value");
      const jobId = element.attr("job");
      const color = element.attr("color");
      const title = element.attr("buttonTitle");
      const indexCount = $("#buttonTable").find("tr").length;

      if (element.is(":checked")) {
        const row = `<tr id="response_${responseId}" class="magicalButtonMoveRow buttonRow buttonNumber-${responseId}"><td style="vertical-align: top"><a job="${jobId}" response="${responseId}" class="dragarrowicon" style="margin-right: 5px;" title="[[Drag]]"><i class="orange" style="font-size: 50px;"></i></a></td><td><a class="button play big ${color}" data-response="${responseId}" >&nbsp;${title}&nbsp;</a></td></tr>`;
        $("#buttonTable").append(row);

        $.ajax({
          url: `/job_sale/add_button_to_jobsale/${jobId}/${responseId}/${indexCount}`,
          success() {
            updateOrder(
              $("#button_responses_order"),
              "/job_sale/update_order/"
            );
          },
        });
      } else {
        $(`.buttonNumber-${responseId}`).remove();
        $.ajax({
          url: `/job_sale/remove_button_from_job/${jobId}/${responseId}`,
          success() {
            updateOrder(
              $("#button_responses_order"),
              "/job_sale/update_order/"
            );
          },
        });
      }
    });
    $("#button_responses_order").sortable({
      axis: "y",
      update() {
        updateOrder($(this), "/job_sale/update_order/");
      },
    });
  });
  function notificationsUpdateFromServer() {
    updateMessages();
  }
  function displayNotifications() {
    if ($("#messages_popup").is(":visible")) {
      $("#messages_popup").hide(animationTime);
      setTimeout(() => {
        displayNotifications();
      }, animationTime);
      return;
    }
    $("#messages_popup").hide();
    $("#cover").hide();
    $("#NotificationWrapper").show(animationTime);
  }
  function updateSeenNotifications() {
    let update = false;
    if (currentNotifications.forEach) {
      currentNotifications.forEach((notification) => {
        if (!notification.seenAt) {
          update = true;
        }
      });
    }
    if (!update) {
      return;
    }
    $.ajax({
      type: "post",
      url: "/notifications/notifications_seen",
      success() {
        $(".message").removeClass("unseen");
        $("#NotificationsAlarmIcon")
          .removeClass("alarm")
          .html('<i class="ion-android-notifications-none"></i>');
      },
      error() {},
    });
  }
  function hideMessages() {
    if ($("#messages_popup").is(":visible")) {
      $("#messages_popup").hide(animationTime);
      setTimeout(() => {
        hideMessages();
      }, animationTime);
      return;
    }
    $("#cover").hide();
    $("#NotificationWrapper").hide(animationTime);
    updateSeenNotifications();
  }
  function updateMessages() {
    if (document.hasFocus()) {
      $.ajax({
        type: "get",
        dataType: "html",
        async: true,
        contentType: "application/x-www-form-urlencoded; charset=UTF-8",
        url: "/notifications/messages/",
        success(data) {
          let messages = "";
          try {
            messages = JSON.parse(data);
          } catch (e) {}

          if (!messages) {
            return;
          }
          if (
            JSON.stringify(messages) == JSON.stringify(currentNotifications)
          ) {
            return;
          }
          currentNotifications = messages;
          localstoragePersistNotifications(currentNotifications);
          UpdateNotificationDisplay();
        },
      });
    }
  }
  function removeMessage(id) {
    $.ajax({
      url: `/Notifications/Remove/${id}`,
    });
  }
  function UpdateNotificationDisplay() {
    const messages = [];
    let unSeen = 0;
    if (currentNotifications.length < 1) {
      return;
    }
    if (currentNotifications.forEach) {
      currentNotifications.forEach((message, index) => {
        if (
          index === 0 &&
          Math.floor(Date.now() / 1000) - message.date_created < 5
        ) {
          browserNotification(message.title);
        }
        const m = $("<div>", {
          class: "message",
          "data-id": message.id,
        });
        if (!message.seenAt) {
          unSeen++;
          m.addClass("unseen");
        }
        const icon = $("<div>", {
          style: `color:#${message.color}`,
          class: `${message.icon} icon`,
        });
        m.append(icon);
        const title = $("<div>", {
          class: "title",
          html: message.title,
        });
        const rm = $("<i>", {
          class: "ion-android-cancel deleteButton",
        });
        rm.click((event) => {
          event.stopPropagation();
          removeMessage(message.id);
          m.remove();
        });
        m.append(rm);
        if (message.openedAt) {
          m.append(
            $("<i>", { class: "ion-android-checkbox-outline open-indicator" })
          );
          m.addClass("open");
        } else {
          m.append(
            $("<i>", {
              class: "ion-android-checkbox-outline-blank open-indicator",
            })
          );
          m.addClass("unopen");
        }
        m.append(title);
        const dateTime = $("<div>", {
          class: "datetime",
          html: message.createdAt,
        });
        m.append(dateTime);
        const subtitle = $("<div>", {
          class: "subtitle",
          style: "font-size:12px;",
          html: message.subtitle,
        });
        m.append(subtitle);
        messages.push(m);
      });
    }
    if (UnreadNotificationCount !== unSeen) {
      UnreadNotificationCount = unSeen;
      const bubble = $("<span>", {
        class: "bubble",
        html: UnreadNotificationCount,
      });
      if (UnreadNotificationCount > 0) {
        $("#NotificationsAlarmIcon")
          .html('<i class="ion-android-notifications"></i>')
          .append(bubble);
      } else {
        $("#NotificationsAlarmIcon").html(
          '<i class="ion-android-notifications-none"></i>'
        );
      }
    }
    $("#NotificationWrapper .messages").html(messages);
  }
  function localstoragePersistNotifications(notifications) {
    localStorage.setItem("notification_items", JSON.stringify(notifications));
  }
  function localstorageGetNotifications() {
    const items = localStorage.getItem("notification_items");
    if (items) {
      if (typeof items === "string") {
        return JSON.parse(items);
      }
      return items;
    }
    return [];
  }
  function browserNotification(textToNotify) {
    //Disabled notifications for now as they are missbehaving
    /*
		var notification;
		// Let's check if the browser supports notifications
		if (!("Notification" in window)) {
		}
		else if (Notification.permission === "granted") {
			// If it's okay let's create a notification
			notification = new Notification("Zenter", {
				body: textToNotify,
				'icon': 'favicon.ico'
			});
		}
		else if (Notification.permission !== 'denied') {
			Notification.requestPermission(function (permission) {
				// If the user accepts, let's create a notification
				if (permission === "granted") {
					notification = new Notification("Zenter", {
						body: textToNotify,
						'icon': 'favicon.ico'
					});
				}
			});
		}
		if (notification) {
			setTimeout(notification.close.bind(notification), 10 * 1000);
		}
		*/
  }
  $(document).ready(() => {
    $("body").on("click", ".IBallonPopup", function (event) {
      const videoWebm = $(this).attr("data-webm");
      const videoMp4 = $(this).attr("data-mp4");
      let dialog;
      event.preventDefault();
      $("#IBalloonDialog").dialog({
        position: {
          my: "center",
          at: "top+25%",
          of: window,
        },
        width: "750px",
        modal: true,
        open(event, ui) {
          const markup = `<video class="video-js vjs-default-skin" controls preload="auto"  width="700" height="393" autoplay data-setup="{}"><source src="${videoWebm}" type="video/webm"/><source src="${videoMp4}" type="video/mp4" /></video>`;
          $(this).html(markup);
          dialog = $(this);
          $(".ui-widget-overlay").bind("click", () => {
            dialog.html("");
            dialog.dialog("destroy");
          });
        },
        close() {
          dialog.html("");
          dialog.dialog("destroy");
        },
        resizable: false,
        dialogClass: "BalloonDialog",
      });
    });

    let el;

    $(".AssetPicker").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <AssetPicker key={index} {...$(el).data()} />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept("../components/Assets/AssetPicker", () => {
          ReactDOM.render(<Root />, el);
        });
      }
    });

    $(".SendEmailSettings").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <SendEmailSettings key={index} {...$(el).data()} />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept("../components/JobLead/SendEmailSettings", () => {
          ReactDOM.render(<Root />, el);
        });
      }
    });

    $(".SendSMSSettings").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <SendSMSSettings key={index} {...$(el).data()} />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept("../components/JobLead/SendSMSSettings", () => {
          ReactDOM.render(<Root />, el);
        });
      }
    });

    $(".sectionOldbutton").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <SectionButton
            jobId={$(el).data("job")}
            sectionNumber={$(el).data("sectionnumber")}
          />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept();
        //module.hot.accept('../components/Pages/Jobs/Shared/AddOldSectionButton', () => {
        ReactDOM.render(<Root />, el);
        //})
      }
    });

    $(".reactAudienceToggle").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <AudienceToggle
            recipientId={$(el).data("recipient")}
            audienceId={$(el).data("audience")}
            checked={$(el).data("checked")}
          />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept();
        //module.hot.accept('../components/Pages/Jobs/Shared/AddOldSectionButton', () => {
        ReactDOM.render(<Root />, el);
        //})
      }
    });

    $(".globalRecipientSearch").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <GlobalRecipientSearch />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);

      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept();
        //module.hot.accept('../components/Pages/Jobs/Shared/AddOldSectionButton', () => {
        ReactDOM.render(<Root />, el);
        //})
      }
    });

    $(".addRecipientAsContact").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <FindAndAddCompanyToContact recipientId={$(el).data("recipient")} />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);

      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept();
        //module.hot.accept('../components/Pages/Jobs/Shared/AddOldSectionButton', () => {
        ReactDOM.render(<Root />, el);
        //})
      }
    });

    $(".InputContentArticle").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <Articles
            jobId={$(el).data("job")}
            templateId={$(el).data("template")}
            className="list"
          />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
    });

    $(".InputContentProduct").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <Products
            jobId={$(el).data("job")}
            templateId={$(el).data("template")}
            className="list"
          />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
    });

    $(".InputContentButton").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <Buttons
            jobId={$(el).data("job")}
            templateId={$(el).data("template")}
            className="list"
          />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);
    });

    $(".ContactChooser").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <FindAndAddContactToCompany recipientId={$(el).data("recipient")} />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);

      if (module.hot) {
        // @ts-ignore doesn't know about hot reaload
        module.hot.accept();
        //module.hot.accept('../components/Pages/Jobs/Shared/AddOldSectionButton', () => {
        ReactDOM.render(<Root />, el);
        //})
      }
    });

    $(".QuickNewOpportunity").each((index, el) => {
      const Root = () => (
        <BaseApp>
          <QuickNewOpportunitySettings key={index} {...$(el).data()} />
        </BaseApp>
      );

      ReactDOM.render(<Root />, el);

      if (module.hot) {
        // @ts-ignore doesn't know about hot reload
        module.hot.accept();
        ReactDOM.render(<Root />, el);
      }
    });
  });
})();

const popupWindow = function (url, width, height) {
  if (window.popupRef !== undefined) {
    window.popupRef.close();
  }
  window.popupRef = window.open(url, "", `width=${width},height=${height}`);
};

$(window).load(() => {
  const displayDataSrc = function (index) {
    const element = $(images[index]);
    const src = element.attr("data-src");
    if (src) {
      element.attr("src", src);
    }
  };
  var images = $("img");
  const imgCount = images.length;
  let current = 0;
  var looper = function () {
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    displayDataSrc(current++);
    if (current < imgCount) {
      setTimeout(looper, 1);
    }
  };
  looper();
});
const JobResponseColors = [
  "#2965aa",
  "#a8322f",
  "#87a939",
  "#6e5094",
  "#288da2",
  "#e2df30",
  "#ce2ca8",
  "#d47822",
  "#43c282",
  "#2dc2b6",
];

window.JobResponseColors = JobResponseColors;

window.enableLoadingOverlay = enableLoadingOverlay;
window.disableLoadingOverlay = disableLoadingOverlay;
window._GET = _GET;
window.highChartifyColor = Utilites.highChartifyColor;

//Input contents
window.bindColorPickers = bindColorPickers;

//Select recipients
window.job_recipient_filter_edit_vars = job_recipient_filter_edit_vars;
window.job_recipient_filter_remove = job_recipient_filter_remove;
window.job_recipient_filter_edit_basevars = job_recipient_filter_edit_basevars;
window.job_recipient_filter_add_lists = job_recipient_filter_add_lists;
window.job_recipient_filter_add_vars = job_recipient_filter_add_vars;
window.job_recipient_filter_add_basevars = job_recipient_filter_add_basevars;
window.job_recipient_filter_edit_basevars_save = job_recipient_filter_edit_basevars_save;
window.job_recipient_filter_edit_vars_save = job_recipient_filter_edit_vars_save;
window.popup_close = popup_close;
window.makeCKEditor = makeCKEditor;

window.transliteration_map = transliteration_map;

//For the impage picker in input contents
window.InputContentImagePickerUploadComplete = InputContentImagePickerUploadComplete;

//For the file picker in input contents
window.InputContentFilePickerUploadComplete = InputContentFilePickerUploadComplete;

//Survey report
window.renderAnswersPie = renderAnswersPie;

window.popupWindow = popupWindow;
window.update_connection = update_connection;
window.FileUploadHandler = FileUploadHandler;

window.reattachLinkConfirm = function () {
  $("a.confirm").click(confirm_link);
};

// @ts-ignore - We are creating unique on the array prototype
Array.prototype.unique = function (func) {
  const localInstanceCache = [];
  return this.filter((item, position) => {
    if (localInstanceCache.indexOf(func(item)) < 0) {
      localInstanceCache.push(func(item));
      return true;
    }
    return false;
  });
};
